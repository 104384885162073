const removeCaptcha = () => {
    var item = document.querySelector(".grecaptcha-badge");
    if (item != null) {
        item.style.display = "none";
    }

};

const addCaptcha = () => {
    var item = document.querySelector(".grecaptcha-badge");
    if (item != null) {
        item.style.display = "block";
    }

};

const executeCaptcha = (actionName) => {
    return document.executeCaptcha(actionName);
};

export { removeCaptcha, executeCaptcha, addCaptcha };